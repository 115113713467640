@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.app-nav-container {
  @apply max-w-7xl mx-auto p-4 sm:p-6;
}
.app-main-container {
  @apply max-w-7xl mx-auto px-4 py-4 sm:py-12;
}
.app-small-main-container {
  @apply max-w-3xl mx-auto px-4 py-4 sm:py-12;
}
.app-modal-width {
  width: min(90vw, 400px);
  max-height: 90vh;
}
.modal-open {
  overflow: hidden;
}
.app-kitchen-container {
  @apply mx-auto py-4 sm:py-12;
  padding-left: 5%;
  padding-right: 5%;
}
.print-bill-main-container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.app-print-bill-container {
  width: 300px;
}
.img-size {
  width: 80px;
}
.switch {
  position: relative;
  display: inline-block;
  height: calc(12px + 2px * 2);
  width: calc(12px * 2 + 2px * 2);
  min-width: calc(12px * 2 + 2px * 2);
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch input:checked + .slider {
  background-color: #333333;
}
.switch input:focus + .slider {
  box-shadow: 0 0 1px black;
}
.switch input:checked + .slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cccccc;
  -webkit-transition: 0.15s;
  transition: 0.15s;

  border-radius: calc(12px + 2px * 2);
}
.slider:before {
  position: absolute;
  content: '';
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: 'Select some files';
  display: inline-block;
  background: linear-gradient(to bottom, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

button {
  @apply active:scale-90 hover:scale-105 transition-all duration-200;
}

button:disabled {
  @apply active:scale-100 hover:scale-100;
}
.app-add-menu-header-container {
  @apply max-w-7xl mx-auto px-4 py-2 sm:py-6;
}
